.App {
	text-align: center;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	position: fixed;
	font-family: "Montserrat", sans-serif;
}

.reportUnavailable {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.reportUnavailable h1 {
	font-size: 20px;
	color: #7aad5c;
	//	font-family: "Montserrat-SemiBold-Italic";
	font-size: 16;
	font-weight: bold;
	font-style: italic;
}
